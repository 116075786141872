import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export default function Inicio() {
  const router = useRouter()
  useEffect(() => {
    router.push('https://www.eskolare.com')
  })

  return (
    <Head>
      <title>Eskolare Shop</title>
      <meta name="description" content="Eskolare Shop" />
    </Head>
  )
}
